<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account"  class="width_200" placeholder="请输入账号" @keydown.enter="getList()" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <div class="data-bg">
      <div class="data-title">
        <div class="data-text">下级总数据</div>
      </div>
      <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
          <el-row class="information">
            <el-col class="data-image" :span="7">
              <img src="../../assets/icon/12.png" alt />
            </el-col>
            <el-col :span="17">
              <div class="data-content-top">渠道总人数</div>
              <div class="data-content-bottom">{{tableData.total_child || 0}}</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
          <el-row class="information">
            <el-col class="data-image" :span="7">
              <img src="../../assets/icon/10.png" alt />
            </el-col>
            <el-col :span="17">
              <div class="data-content-top">渠道总充值</div>
              <div class="data-content-bottom">{{tableData.total_pay || 0}}</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
          <el-row class="information">
            <el-col class="data-image" :span="7">
              <img src="../../assets/icon/15.png" alt />
            </el-col>
            <el-col :span="17">
              <div class="data-content-top">今日新增</div>
              <div class="data-content-bottom">{{tableData.count_reg || 0}}</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
          <el-row class="information">
            <el-col class="data-image" :span="7">
              <img src="../../assets/icon/17.png" alt />
            </el-col>
            <el-col :span="17">
              <div class="data-content-top">今日活跃</div>
              <div class="data-content-bottom">{{tableData.active_count || 0}}</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
          <el-row class="information">
            <el-col class="data-image" :span="7">
              <img src="../../assets/icon/14.png" alt />
            </el-col>
            <el-col :span="17">
              <div class="data-content-top">今日充值</div>
              <div class="data-content-bottom">{{tableData.total_toady_pay || 0}}</div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="data-bg">
      <div class="data-title">
        <div class="data-text">30天注册走势</div>
      </div>
      <div class="data-content">
        <echarts :uid="1" :options="options" />
      </div>
    </div>
    <div class="data-bg">
      <div class="data-title">
        <div class="data-text">30天充值走势</div>
      </div>
      <div class="data-content">
        <echarts :uid="2" :options="options2" />
      </div>
    </div>
  </div>
</template>


<script>
import { getList } from "@/api/users/agent";
import echarts from "@/components/echarts";
export default {
  name: "userAgent",
  components: { echarts },
  data() {
    return {
      tableData: [], //数据
      search: {
        account: "",
      }, //搜索
      options: {
        xAxis: { type: "category", data: ["暂无数据"] },
        yAxis: { type: "value" },
        series: [{ data: [0], type: "line", smooth: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", label: { backgroundColor: "#6a7985" } },
        },
      },
      options2: {
        xAxis: { type: "category", data: ["暂无数据"] },
        yAxis: { type: "value" },
        series: [{ data: [0], type: "line", smooth: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", label: { backgroundColor: "#6a7985" } },
        },
      },
    };
  },
  mounted() {},
  methods: {
    getList() {
      if(!this.search.account) return this.$message({ message: "搜索账号不能为空", type: "error" });
      getList([{ key: "account", val: this.search.account }])
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
            this.options.xAxis.data = this.tableData.time_arr;
            this.options.series[0].data = this.tableData.reg_arr;
            this.options2.xAxis.data = this.tableData.time_arr;
            this.options2.series[0].data = this.tableData.pay_arr;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.table-tr {
  height: 40px;
  line-height: 40px;
}
.table-title {
  width: 150px;
  padding: 0 10px;
}
.table-content {
  width: 400px;
  padding: 0 10px;
}
.information {
  padding: 33px;
  height: 130px;
}
.data-image {
  text-align: center;
}
.information img {
  height: 50px;
  width: 50px;
}
</style>